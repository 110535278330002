function addTooltip() {
    $('body:not(.touch-screen) [data-toggle="tooltip"]').tooltip({
        position: { my: "center bottom-8", at: "center top" },
        hide: false,
        show: false,
    });
}

function updateCustomerDropdownMenu() {
    var $otherLinks = $('.js-otherCustomerDropdownLinks');
    var $hookContent = $('<div/>').html($('.js-displayCustomerAccount').data('hook-content'));
    if ($otherLinks.length && $hookContent.length) {
        $hookContent.children('a').each(function() {
            if (this.getAttribute('id') !== 'psgdpr-link') {
                $(this).removeAttr('id').removeAttr('class').addClass('dropdown-item');
                var $i = $(this).find('i');
                $(this).find('i').remove();
                var $text = $(this).text();
                if (!$i.length) {
                    $i = $(this).find('img');
                }
                if ($text.length && $i.length) {
                    $(this).html('<span>' + $text.trim() + '</span>').append($i);
                }
    
                var $newThis = $(this).wrap('<li></li>').parent();
                $newThis.insertBefore($otherLinks);
            }
        });
    }
}

function expandPSCategoryTree() {
    var currentCatID = $('.js-category-page').data('current-category-id');

    if (currentCatID !== 'undefined' && currentCatID !== '') {
        var $currentCatObj = $('.js-category-tree [data-category-id=' + currentCatID + ']');

        $currentCatObj.addClass('current');
        $currentCatObj.parents('li').each(function() {
            $(this).children('[data-toggle="collapse"]').attr('aria-expanded', 'true');
            $(this).children('.category-sub-menu.collapse').addClass('show');
        });
    }
}

function categoryDescriptionExpand() {
    var $catDesc = $('.js-expand-description');
    if ($catDesc.length) {
        let maxHeight = $('.descSmall', $catDesc).height(),
            realHeight = $('.descFull', $catDesc).height();
        if (realHeight > maxHeight) {
            $catDesc.addClass('descCollapsed');
            $('.descSmall', $catDesc).css('max-height', 'none').height(maxHeight);
            
            $('.descToggle.expand a', $catDesc).on('click', function() {
                $catDesc.removeClass('descCollapsed').addClass('descExpanded');
                $('.descSmall', $catDesc).height(realHeight + 30);
                return false;
            });
            $('.descToggle.collapse a', $catDesc).on('click', function() {
                $catDesc.addClass('descCollapsed').removeClass('descExpanded');
                $('.descSmall', $catDesc).height(maxHeight);
                return false;
            });
        }
    }
}

function mobileMenuControl() {
  $('#mobile-menu-icon').on('click', function() {
    $('#dropdown-mobile-menu').stop().slideToggle();
    $('html').toggleClass('js-overflow-hidden');
  });
}

function typoImageSlider() {
    $('.js-typoImageSlider').makeFlexScrollBox({
        arrows: '.scrollArrows',
    });
}

function typoModalBox() {
  var $eModal = $('#extraModal');
  $('.js-typoModalBox').on('click', function(){
    let content = $($(this).attr('href')).html();

    $eModal.find('.js-modal-extra-content').html(content);
    $eModal.modal('show');

    return false;
  });
}

function typoShowMoreContent() {
    var $content = $('.js-typoShowMoreContent');
    if ($content.length) {
        let maxHeight = $('.descSmall', $content).height(),
            realHeight = $('.descFull', $content).height();
        if (realHeight > maxHeight) {
            $content.addClass('descCollapsed');
            $('.descSmall', $content).height(maxHeight);
            
            $('.descToggle .expand', $content).on('click', function() {
                $content.removeClass('descCollapsed').addClass('descExpanded');
                $('.descSmall', $content).height(realHeight + 30);
                return false;
            });
            $('.descToggle .collapse', $content).on('click', function() {
                $content.addClass('descCollapsed').removeClass('descExpanded');
                $('.descSmall', $content).height(maxHeight);
                return false;
            });
        }
    }
}

function typoLightboxGallery() {
    $('.js-typoLightboxGallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        mainClass: 'typo-mfp-gallery',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1]
        }
    });
}

$(window).on('load', function() {
    addTooltip();
    updateCustomerDropdownMenu();
    expandPSCategoryTree();
    categoryDescriptionExpand();
    typoImageSlider();
    typoModalBox();
    typoShowMoreContent();
    typoLightboxGallery();
});