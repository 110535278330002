function updateDropdownPositionLTR(mm) {
  const styles = {
    full: 'full',
    initial: 'initial',
    right: 'right',
    left: 'left',
    middle: 'middle',
  };
  const marginLeft = mm.offset().left;
  const marginRight = mm.offset().left + mm.outerWidth();
  const screenWidth = window.innerWidth;
  // define if dropdown is full
  const styleSelected = styles.full;
  // define if dropdown is align left or right
  const styleAlign = styles.left;
  const container = 1620;

  if (styleSelected === styles.right) {
    document.querySelectorAll('.adropdown').forEach((dropdown) => {
      const dropdownCurrent = dropdown;
      const menuItem = dropdownCurrent.closest('.amenu-item');
      const menuItemLeft = 0 - menuItem.getBoundingClientRect().left;
      dropdownCurrent.style.marginLeft = `${menuItemLeft}px`;
      dropdownCurrent.style.width = `${window.outerWidth}px`;
    });
  } else if (styleSelected === styles.initial) {
    document.querySelectorAll('.adropdown').forEach((dropdown) => {
      const dropdownCurrent = dropdown;
      const menuItem = dropdownCurrent.closest('.amenu-item');
      const menuItemWidth = menuItem.offsetWidth;
      const menuItemLeft = menuItem.offsetLeft+ marginLeft;
      const menuItemRight = menuItemLeft + menuItemWidth;
      const dropdownWidth = dropdownCurrent.offsetWidth;
      const dropdownLeft = dropdownCurrent.offsetLeft;
      const dropdownMiddle = 0 - (dropdownWidth) / 2;
      const dropdownRight = dropdownLeft + dropdownWidth;
      const margin = Number(dropdownCurrent.style.marginLeft.slice(0, -2));
      const containerMarge = (screenWidth - container) / 2;

      if (styleAlign === styles.right) {
        if (dropdownRight - margin + dropdownMiddle + menuItemLeft > screenWidth) {
          dropdownCurrent.style.marginLeft = `${marginRight - menuItemLeft - dropdownWidth}px`;
        } else if (marginRight > menuItemLeft + dropdownWidth) {
          dropdownCurrent.style.marginLeft = `-${menuItemLeft}px`;
        } else {
          dropdownCurrent.style.marginLeft = `${dropdownMiddle + (menuItemWidth / 2)}px`;
        }
      } else if (styleAlign === styles.left) {
        if (dropdownLeft - margin + dropdownMiddle + menuItemLeft > -marginLeft && marginLeft !== 0) {
          dropdownCurrent.style.marginLeft = `${-menuItemLeft + containerMarge}px`;
        } else if (marginLeft === 0 && -menuItemLeft > dropdownMiddle) {
          dropdownCurrent.style.marginLeft = `${-menuItemLeft +containerMarge}px`;
        } else {
          dropdownCurrent.style.marginLeft = `${dropdownMiddle + containerMarge + (menuItemWidth / 2)}px`;
        }
      }
    });
  }
}

function updateDropdownPositionRTL(mm) {
  let m_left = mm.offset().left,
      m_right = mm.offset().left + mm.outerWidth();

  $('.adropdown', mm).each(function () {
    let t = $(this),
      p = t.parent(),
      i = 0 - (t.outerWidth() - p.outerWidth()) / 2,
      t_right = t.offset().left + t.outerWidth(),
      p_right = p.offset().left + p.outerWidth(),
      margin = parseInt(t.css('margin-right'));

    if (t.offset().left + margin - i < m_left) {
      t.css('margin-right', (0 - (t.outerWidth() - p_right + m_left)) + 'px');
    } else if (t_right + margin - i > m_right) {
      t.css('margin-right', (0 - (m_right - p_right)) + 'px');
    } else {
      t.css('margin-right', i + 'px');
    }
  });
}

function updateDropdownPosition() {
  const $amegamenu = $('#amegamenu');
  if ($amegamenu.length) {
    const updatePosition = () => {
      if ($amegamenu.hasClass('amegamenu_rtl')) {
        updateDropdownPositionRTL($amegamenu);
      } else {
        updateDropdownPositionLTR($amegamenu);
      }
    };
    updatePosition();
    $(window).on('resize', updatePosition);
  }
}

function sldCreateButtonBack() {
  const icon = document.createElement('span');
  icon.classList.add('material-icons');
  icon.innerText = 'keyboard_arrow_left';
  const button = document.createElement('button');
  button.classList.add('sld-js-menu-back-button');
  button.append(icon);
  button.setAttribute('data-category-active', '0');
  button.setAttribute('data-category-parent', '');
  return button;
}

function sldShowBackCategory() {
  const categoryActive = this.getAttribute('data-category-active');
  const categoryName = this.getAttribute('data-category-parent');
  const navbarBack = document.querySelector('.sld-menu-navbar-back');
  const navbarTitle = navbarBack.querySelector('.sld-menu-navbar-name');
  if (typeof categoryActive !== 'undefined') {
    const submenuActive = document.querySelector(`ul[data-submenu-id="${categoryActive}"]`);
    const submenuParent = submenuActive.parentElement.closest('ul');
    const submenuParentName = submenuParent.parentElement.closest('ul');
    const submenuParentId = submenuParent.getAttribute('data-submenu-id');
    submenuActive.classList.remove('active');
    if (submenuParentId !== null) {
      this.setAttribute('data-category-active', submenuParentId);
    } else {
      this.setAttribute('data-category-active', 0);
      navbarBack.classList.remove('active');
    }
    if (submenuParentName !== null) {
      const name = submenuParentName.getAttribute('data-category-parent-name');
      navbarTitle.textContent = categoryName;
      this.setAttribute('data-category-parent', name);
    } else {
      navbarTitle.textContent = '';
    }
  }
}

function sidebarClose() {
  document.body.classList.remove('st-effect-left', 'st-menu-open');
  const burgerHeader = document.querySelector('#header .left-nav-trigger');
  if (burgerHeader !== null) {
    burgerHeader.classList.remove('active');
  }
  const navbarBack = document.querySelector('.sld-menu-navbar-back');
  if (navbarBack !== null) {
    navbarBack.classList.remove('active');
  }
  setTimeout(() => {
    const dropdowns = document.querySelectorAll('.adropdown.active, .sld-menu-container-items.active, .sld-category-subs.active, .sld-sub-dropdown.active');
    const menu = document.querySelector('.anav-top');
    if (dropdowns.length > 0) {
      dropdowns.forEach((dropdown) => {
        dropdown.classList.remove('active');
      });
    }
    if (menu !== null) {
      menu.style.height = '';
    }
  }, 500);
}

function sldGenerateNavBack() {
  const buttonBack = sldCreateButtonBack();
  buttonBack.addEventListener('click', sldShowBackCategory, true);
  const categoryName = document.createElement('div');
  categoryName.classList.add('sld-menu-navbar-name');
  const navbar = document.createElement('div');
  navbar.classList.add('sld-menu-navbar-back');
  const menuBurger = document.createElement('i');
  menuBurger.classList.add('sld-menu-burger');
  menuBurger.append(document.createElement('span'));
  const leftNavIcon = document.createElement('div');
  leftNavIcon.classList.add('left-nav-icon');
  leftNavIcon.append(menuBurger);
  const buttonClose = document.createElement('div');
  buttonClose.classList.add('left-nav-trigger', 'active');
  buttonClose.setAttribute('data-left-nav-trigger', '');
  buttonClose.append(leftNavIcon);
  buttonClose.addEventListener('click', (e) => {
    e.preventDefault();
    sidebarClose();
  });
  navbar.append(buttonBack);
  navbar.append(categoryName);
  navbar.append(buttonClose);
  return navbar;
}


function sldBackLink() {
  const headerMain = document.querySelector('.header-main');
  const itemsContainer = document.querySelectorAll('.dropdown-content:first-child, .sld-menu-container-items, .sld-category-subs');
  const linksBack = document.querySelector('.sld-js-menu-back-button');
  if (itemsContainer.length > 0 && linksBack === null) {
    headerMain.append(sldGenerateNavBack());
  }
}

function sldActiveSubmenu(event) {
  event.preventDefault();
  console.log(this);
  const navbarBack = document.querySelector('.sld-menu-navbar-back');
  if (navbarBack !== null) {
    const submenu = this.nextElementSibling;
    const idCategory = submenu.getAttribute('data-submenu-id');
    const nameCategoryParent = submenu.getAttribute('data-category-parent-name');
    const buttonBack = document.querySelector('.sld-js-menu-back-button');
    const categoryParent = document.querySelector('.sld-menu-navbar-name');
    const categoryOld = document.querySelector('.sld-menu-navbar-name');
    let categoryOldName = '';
    if (categoryOld !== null) {
      categoryOldName = categoryOld.textContent;
    }
    buttonBack.setAttribute('data-category-active', idCategory);
    buttonBack.setAttribute('data-category-parent', categoryOldName);
    categoryParent.textContent = nameCategoryParent;
    navbarBack.classList.add('active');
    if (submenu) {
      submenu.classList.add('active');
    }
  }
}

function sldSubMenuSlide() {
  const buttonSubmenu = document.querySelectorAll('.sld-js-menu-submenu-button');
  if (buttonSubmenu.length > 0) {
    buttonSubmenu.forEach((button) => {
      button.addEventListener('click', sldActiveSubmenu, true);
    });
  }
}

function sldDisableSubMenuSlide() {
  const buttonSubmenu = document.querySelectorAll('.sld-js-menu-submenu-button');
  const dropdowns = document.querySelectorAll('.adropdown');
  if (buttonSubmenu.length > 0) {
    buttonSubmenu.forEach((button) => {
      button.removeEventListener('click', sldActiveSubmenu, true);
    });
  }
  if (dropdowns.length > 0) {
    dropdowns.forEach((dropdown) => {
      const dropdownCurrent = dropdown;
      dropdownCurrent.style.height = null;
    });
  }
}

function sldMenuResponsive(active) {
  if (active) {
    sldBackLink();
    sldSubMenuSlide();
  } else {
    sldDisableSubMenuSlide();
  }
}

function sldMoveMenuDesktop(activeMenuMobile) {
  const containerMain = document.querySelector('.header-display-navfullwidth');
  const containerMainMenu = document.querySelector('.js-top-menu');
  const containerLeft = document.querySelector('.sidebar-menu');
  const containerLeftBottom = document.querySelector('.st-menu-bottom');
  const menu = document.querySelector('#amegamenu');
  const mainMenu =document.querySelector('#top-menu');
  if (containerMain !== null && containerLeft !== null && menu !== null) {
    // if column left not contains menu
    if (containerLeft.querySelector('#amegamenu') === null && activeMenuMobile) {
      containerLeft.appendChild(menu);
      if (containerLeftBottom !== null && mainMenu !== null) {
        containerLeftBottom.appendChild(mainMenu);
      }
      sldMenuResponsive(true);
    } else if (containerMain.querySelector('#amegamenu') === null && !activeMenuMobile) {
      containerMain.appendChild(menu);
      if (containerMainMenu !== null && mainMenu !== null) {
        containerMainMenu.appendChild(mainMenu);
      }
      sldMenuResponsive(false);
    }
  }
}

function createNavSubDropdown() {
  let nav = document.createElement('div');
  nav.classList.add('sld-nav-sub-dropdown', 'nav', 'nav-tabs');
  return nav;
}

function sldToggleSubDropdown(dropdown, anchor) {
  let subDropdownActive = dropdown.querySelector('.sub-dropdown-content.active');
  if (subDropdownActive !== null) {
    subDropdownActive.classList.remove('active');
  }
  document.querySelector(anchor).classList.add('active');
}

function moveLinksSubDropdown(active) {
  if (active) {
    let links = document.querySelectorAll('.sld-js-sub-dropdown-link');
    let navSubDropdown = null;
    links.forEach((link, key) => {
      let dropdown = link.closest('.adropdown');
      if (dropdown.querySelector('.sld-nav-sub-dropdown') === null) {
        dropdown.prepend(createNavSubDropdown());
        navSubDropdown = dropdown.querySelector('.sld-nav-sub-dropdown');
        let sub = dropdown.querySelector('.sld-nav-sub-dropdown + .sub-dropdown-content');
        sub.classList.add('active');
      }
      if (navSubDropdown !== null) {
        let linkClone = link.cloneNode(true);
        if (navSubDropdown.childElementCount === 0) {
          linkClone.classList.add('active');
        }
        linkClone.addEventListener('click', function (e) {
          e.preventDefault();
          let dropdownLink = dropdown.querySelector('.sld-js-sub-dropdown-link.active');
          if (dropdownLink !== null) {
            dropdownLink.classList.remove('active');
          }
          linkClone.classList.add('active');
          let anchor = linkClone.getAttribute('href');
          sldToggleSubDropdown(dropdown, anchor);
        });
        navSubDropdown.appendChild(linkClone);
      }
    });

  }
  else {
    let subdropdowns = document.querySelectorAll('.sld-nav-sub-dropdown.nav');
    if (subdropdowns.length > 0) {
      subdropdowns.forEach((nav) => {
        nav.remove();
      })
    }
  }

}

function sldBreakWidth() {
  if (window.innerWidth < 1260 && !document.body.classList.contains('sld-menu-responsive')) {
    sldMoveMenuDesktop(true);
    moveLinksSubDropdown(false);
    document.body.classList.add('sld-menu-responsive');
    const dropdowns = document.querySelectorAll('.adropdown');
    if (dropdowns.length > 0) {
      dropdowns.forEach((dropdown) => {
        const dropdownCurrent = dropdown;
        dropdownCurrent.style.width = '';
        dropdownCurrent.style.marginLeft = '';
        dropdownCurrent.style.height = '';
      });
    }
  } else if (window.innerWidth >= 1260 && document.body.classList.contains('sld-menu-responsive')) {
    sldMoveMenuDesktop(false);
    moveLinksSubDropdown(true);
    document.body.classList.remove('sld-menu-responsive');
  }
  else {
    moveLinksSubDropdown(true);
  }
}

function ajaxLoadDropdownContent() {
  const $ajaxmenu = $('.js-ajax-mega-menu');
  if ($ajaxmenu.length) {
    $.ajax({
      type: 'POST',
      url: $ajaxmenu.data('ajax-dropdown-controller'),
      data: {
        ajax: true,
      },
      dataType: 'json',
      success: function (data) {
        updateDrodownContent(data);
        // if mobile
        if (document.querySelector('#mobile-amegamenu') !== null) {
          sldMenuResponsive(true);
        } else {
          // init page
          sldBreakWidth();
        }
      },
      error: function (err) {
        console.log(err);
      },
    });

    let updateDrodownContent = (dropdown) => {
      $('.js-dropdown-content', $ajaxmenu).each(function () {
        const item = $(this).data('menu-id');
        $(this).replaceWith(dropdown[item]);
      });
    };
  }
}

window.addEventListener('load', () => {
  setTimeout(() => {
    ajaxLoadDropdownContent();
    updateDropdownPosition();
  }, 600);
});

// event resize
window.addEventListener('resize', () => {
  sldBreakWidth();
});
